<template>
  <v-menu :close-on-content-click="true" :nudge-width="200" offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on" class="menuBtn" @click="checkUser()">
        <v-icon>mdi-account</v-icon>
      </v-btn>
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" class="menuBtn" @click="help()">
            <v-icon>mdi-help-circle-outline</v-icon>
          </v-btn>
        </template>
        <span>Help</span>
      </v-tooltip>
    </template>

    <v-card>
      <v-list>
        <v-list-item>
          <v-list-item-avatar>
            <v-icon x-large>mdi-face</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ user }}</v-list-item-title>
            <v-list-item-subtitle>{{ email }}</v-list-item-subtitle>
            <v-list-item-subtitle>{{ userProfile }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list>
        <v-list-item-group color="primary">
          <v-list-item>
            <v-list-item-title @click="logout()">Logout</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import userMixin from "@/mixins/AppBarMenuUser.js";
import connectionMixin from "@/mixins/connectionParams.js";
export default {
  name: "AppBarMenuUser",
  mixins: [connectionMixin, userMixin],
  data: () => ({
    userProfile: "",
    displayProgress: false,
  }),
  methods: {
    async checkUser() {
      this.displayProgress = true;
      try {
        const response = await this.$http.get(
          this.$env.value("API_USER"),
          this.opts
        );
        this.userProfile = response.data;

        this.loading = false;
      } catch (error) {
        console.log(error);
        alert("Si prega di ricaricare la pagina");
        this.displayProgress = false;
      }
    },
    async help() {
      window.open("static/help/index.html", "_blank");
    },
  },
};
</script>
